/* eslint jsx-a11y/html-has-lang: 0 */
import React from "react"
import styled, { keyframes } from "styled-components"
import Backdrop from "./Backdrop"

const styledKeyframes = keyframes`
  0%, 100% {
    transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
  }
`

const LoaderBackdrop = styled(Backdrop)`
  display: ${(p) => (p.active ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`

const Loading = styled.div`
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
`

const Circle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${(p) => p.color || p.theme.colors.white};
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${styledKeyframes} 2s infinite ease-in-out;
`

const CircleTwo = styled(Circle)`
  animation-delay: -1s;
`

export const Loader = ({ color, ...other }) => (
  <Loading {...other}>
    <Circle color={color} />
    <CircleTwo color={color} />
  </Loading>
)

const LoaderWithBackdrop = ({ color, isLoading, ...other }) => (
  <LoaderBackdrop active={isLoading}>
    <Loader color={color} {...other} />
  </LoaderBackdrop>
)

export default LoaderWithBackdrop
